import axios, { AxiosInstance } from "axios";

const BASE_URL = typeof window !== "undefined" ? window?.location?.origin : "";
const LOCAL_ENV = "https://haltek-dog.trackwayz.com/api/"; // http://192.168.50.24:8081/api/

export const PROD_URL = BASE_URL + "/api/";

const baseURL = process.env.NODE_ENV === "development" ? LOCAL_ENV : PROD_URL;

export const stripeKey = process.env.REACT_APP_STRIPE_API_KEY;

export const baseUrl: AxiosInstance = axios.create({
  baseURL: baseURL,
  headers: { petType: "dog" },
});

//Request
baseUrl.interceptors.request.use(
  function (config: any) {
    return config;
  },
  function (err: any) {
    return Promise.reject(err);
  }
);

// Response
baseUrl.interceptors.response.use(
  function (response: any) {
    if (response.data.responseObject) {
      return response.data.responseObject;
    } else {
      return response.data;
    }
  },
  function (error: any) {
    return error;
  }
);
