import AddCircleIcon from "@mui/icons-material/AddCircle";
import { IconButton } from "@mui/material";
import InputLabel from "@mui/material/InputLabel";
import TextField from "@mui/material/TextField/TextField";
import { regEmail } from "../../utils/helpers/regEx";

interface EmailInputProps {
  name?: string;
  type?: string;
  label?: string;
  value: string;
  onChange: any;
  error?: boolean;
  nameRef?: any;
  onClickAddEmail?: any;
  placeholder?: string;
  disable?: boolean;
}
const EmailInput = (props: EmailInputProps) => {
  const {
    name,
    type = "email",
    value,
    label,
    onChange,
    error,
    nameRef,
    onClickAddEmail,
    placeholder,
    disable
  } = props;

  const helperText =
    value.length > 0
      ? "Please Enter Valid E-mail Address"
      : "Please Enter Email Address";
  return (
    <>
      {label && (
        <InputLabel className="main-text" sx={{ paddingBottom: "8px" }}>
          {label}
        </InputLabel>
      )}
      <TextField
        inputRef={nameRef}
        fullWidth
        name={name}
        value={value}
        placeholder={placeholder}
        disabled={disable}
        type={type}
        error={error && !regEmail.test(value)}
        helperText={error && !regEmail.test(value) && helperText}
        onChange={onChange}
        InputProps={{
          endAdornment: onClickAddEmail && (
            <IconButton
              aria-label="addEmail"
              onClick={onClickAddEmail}
              edge="end"
            >
              <AddCircleIcon sx={{ color: "#6c59b7", fontSize: "32px" }} />
            </IconButton>
          ),
        }}
        variant="outlined"
        sx={{
          "& .MuiFormHelperText-root": { marginLeft: "2px !important" },
          ".MuiOutlinedInput-input": {
            height: "45px",
            padding: "2px 10px",
            borderColor: "#E4E4E4 !important",
            bgcolor: "#FFF !important",
          },
        }}
      />
    </>
  );
};

export default EmailInput;
