import Button from "@mui/material/Button";
import { Box } from "@mui/system";
import { memo, useEffect, useRef, useState } from "react";
import UploadImg from "../../assests/images/petCoverImage.jpg";

export const profileExtension = ".jpg,.png,.jpeg,.heic,.heif";
// export const mutipleFileSupport = "video/*,image/*,.pdf";
export const mutipleFileSupport =
  ".pdf,.jpg,.png,.jpeg,.mp4,.heif,.hevc,.heic,.m4v,.mov,.h.264";
const imageFileSupport = [
  ".jpg",
  ".png",
  ".jpeg",
  ".pdf",
  ".heif",
  ".heic",
  ".hevc",
];
const videoFileSupport = [".mp4", ".m4v", ".mov", ".hevc", ".h.264"];
const acceptMutipleFileSupport = [
  ".pdf",
  ".jpg",
  ".png",
  ".jpeg",
  ".mp4",
  ".heif",
  ".hevc",
  ".heic",
  ".m4v",
  ".mov",
  ".h.264",
];

interface FileUploadProps {
  accept: string;
  updateFilesCb: any;
  filesList: any;
  profile?: boolean;
  multiple?: boolean;
  fileButtonRef?: any;
  error?: boolean;
  helperText?: string;
}
const FileUpload = (props: FileUploadProps) => {
  const {
    accept,
    updateFilesCb,
    profile,
    multiple,
    filesList,
    fileButtonRef,
    helperText,
    error,
  } = props;
  const fileInputRef = useRef<HTMLInputElement | any>(null);

  const [fileValidation, setFileValidation] = useState("");
  const [files, setFiles] = useState<any>([]);

  const oneMb = 1048576;
  const maxVideoFileSize = oneMb * 100;
  const maxImageFileSize = oneMb * 10;

  const isAccepted = profile
    ? accept.toLowerCase().split(",")
    : acceptMutipleFileSupport;

  useEffect(() => {
    setFiles(filesList);
    return () => {};
  }, [filesList]);

  const validateFile = (name: string, size: number) => {
    let idxDot = name.lastIndexOf(".");
    let extFile = name.substr(idxDot, name.length).toLowerCase();

    if (isAccepted.includes(extFile)) {
      let isValid = true;

      if (size > maxVideoFileSize && videoFileSupport.includes(extFile)) {
        setFileValidation("File size should be 100MB for Video");
        isValid = false;
      } else if (
        size > maxImageFileSize &&
        imageFileSupport.includes(extFile)
      ) {
        setFileValidation(
          `File size should be 10MB for ${profile ? "Image" : "Images & Pdf"}`
        );
        isValid = false;
      } else {
        isValid = true;
        setFileValidation("");
      }

      return isValid;
    } else {
      setFileValidation(
        `Upload Only ${isAccepted.toString().replaceAll(",", ", ")} Files`
      );
      return false;
    }
  };

  const addNewFiles = (newFiles: any) => {
    for (let file of newFiles) {
      if (file.size) {
        if (profile) {
          return { file };
        } else {
          files[file.name] = file;
        }
      }
      return { ...files };
    }
  };

  const handleNewFileUpload = (e: any) => {
    e.preventDefault();
    const newFiles = e.target.files;

    let isValidFileFormat = false;
    for (let file of newFiles) {
      isValidFileFormat = validateFile(file.name, file.size);
    }

    if (newFiles.length && isValidFileFormat) {
      const updatedFiles = addNewFiles(newFiles);

      setFiles(updatedFiles);
      const filesAsArray = Object.keys(updatedFiles).map(
        (key) => updatedFiles[key]
      );
      updateFilesCb(filesAsArray);
    }
  };

  return (
    <>
      <Button
        fullWidth={!profile}
        variant={profile ? "text" : "outlined"}
        className={profile ? "" : "see-examples"}
        onClick={(e: any) => {
          e.preventDefault();
          fileInputRef.current.click();
        }}
        ref={fileButtonRef}
        sx={{
          border: `1px solid ${error ? "#e16f6f" : "transparent"}`,
          padding: "0",
        }}
      >
        {profile ? (
          <img
            height={"280px"}
            width="100%"
            alt="upload"
            src={UploadImg}
            style={{
              opacity: "0.4",
              border: `${error ? "0" : "1px"} solid #23222799`,
              borderRadius: "6px",
            }}
          />
        ) : (
          "Add more photos and videos"
        )}
      </Button>
      <input
        accept={accept}
        type="file"
        multiple={multiple}
        ref={fileInputRef}
        style={{ display: "none" }}
        onChange={handleNewFileUpload}
      />
      {(fileValidation || helperText) && (
        <Box
          className="main-text"
          sx={{ color: "#e16f6f !important", textAlign: "left !important" }}
          mt={1}
        >
          {fileValidation || (error && helperText)}
        </Box>
      )}
    </>
  );
};

export default memo(FileUpload);
