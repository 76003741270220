import { Box, Grid } from "@mui/material";
import Button from "@mui/material/Button";
import { makeStyles } from "@mui/styles";
import { memo, useState } from "react";
import Description from "../../components/description";
import Slider from "../../components/slider";
import TributeImageView from "../../components/tributeImageView";
import VideoPlayer from "../../components/videoPlayer";
import { Loader } from "../../widgets/loader";

const useStyles = makeStyles({
  videoPlayer: {
    width: "100% !important",
    borderRadius: "4px",
  },
  carousel: {
    margin: "20px 0 !important",
  },
  carosalImg: {
    width: "100%",
    height: "300px",
  },
});

const ObiturayContentView = (props: any) => {
  const classes = useStyles();
  const [loader, setLoader] = useState(false);
  const { obiturayDetail } = props;

  const downloadOption = obiturayDetail?.path && obiturayDetail?.vimeoPath;
  const name = obiturayDetail?.deceasedPet?.petName;

  // function for video download
  async function handleDownloadVideo() {
    const videoUrl = obiturayDetail?.path;
    try {
      setLoader(true);

      const response = await fetch(videoUrl);
      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = name;
      a.style.display = "none";

      document.body.appendChild(a);

      a.click();

      window.URL.revokeObjectURL(url);
      a.remove();
      setLoader(false);
    } catch (error) {
      console.error("Error downloading video:", error);
      setLoader(false);
    }
  }

  return (
    <>
      <TributeImageView obiturayDetail={obiturayDetail?.deceasedPet} />
      <Box className={classes.videoPlayer}>
        <VideoPlayer vimeoPath={obiturayDetail?.vimeoPath} />
        {downloadOption && (
          <Button
            variant="contained"
            className="modal-button"
            sx={{ marginTop: "10px" }}
            onClick={handleDownloadVideo}
          >
            Download video
          </Button>
        )}
      </Box>

      <Grid
        container
        direction="row"
        alignItems="center"
        justifyContent="center"
        py={3}
      >
        <Description description={obiturayDetail.deceasedPet.description} />
        <Grid item xs={12} className={classes.carousel}>
          <Slider sliderItems={obiturayDetail.obituaryContents} />
        </Grid>
      </Grid>
      <Loader loader={loader} />
    </>
  );
};

export default memo(ObiturayContentView);
